import React, { useEffect } from "react";

const VKPost = (props) => {
  const containerId = `vk_post_${props.owner}_${props.content}`;

  useEffect(() => {
    const vkPostContainer = document.createElement("div");
    vkPostContainer.id = containerId;
    document.body.appendChild(vkPostContainer);

    const vkScript = document.createElement("script");
    vkScript.type = "text/javascript";
    vkScript.src = "https://vk.com/js/api/openapi.js?169";
    document.body.appendChild(vkScript);

    vkScript.onload = function (e) {
      window.VK.Widgets.Post(
        containerId,
        props.owner,
        props.content,
        props.sign
      );
    };
  }, [props, containerId]);

  return <div id={containerId} />;
};

export default VKPost;
