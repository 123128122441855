function upload(photo, callback) {
  fetch(photo)
    .then((res) => res.blob())
    .then((blob) => {
      const fd = new FormData();
      const file = new File([blob], "photo.jpeg");
      fd.append("data", file);

      const API_URL = "https://busyameow.ru/api/upload";
      fetch(API_URL, { method: "POST", body: fd })
        .then((resp) => resp.json())
        .then((resp) => callback(resp));
    });
}

export default upload;
