import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
      toggle: false,
    };

    this.interval = null;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ seconds: this.state.seconds + 1 });
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  reset() {
    this.setState({ seconds: 0, toggle: !this.state.toggle });
  }

  render() {
    return (
      <div className="App-timer">
        {this.state.toggle && (
          <CircularProgressbar
            value={(110 * this.state.seconds) / 100}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#ffffff72",
              trailColor: "transparent",
            })}
          />
        )}
        {!this.state.toggle && (
          <CircularProgressbar
            value={100 - (110 * this.state.seconds) / 100}
            strokeWidth={50}
            counterClockwise={true}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#ffffff72",
              trailColor: "transparent",
            })}
          />
        )}
      </div>
    );
  }
}

export default Timer;
