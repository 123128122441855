import React, { useState, useEffect } from "react";
import "./App.css";
import Webcam from "react-webcam";
import Iframe from "react-iframe";
import QRCode from "react-qr-code";
import VKPost from "./VKPost";
import Timer from "./Timer";
import upload from "./api";
import logo from "./logo512.jpg";

const baseSize = 960;

function App() {
  const [content, setContent] = useState({
    type: null,
    owner_id: -39765623,
    content_id: 66034,
    sign: "80D7lm8AR5_8axxzk4vJ9wv5h3J4",
    url: "https://wall-39765623_66034:80D7lm8AR5_8axxzk4vJ9wv5h3J4",
  });

  const webcamRef = React.useRef(null);
  const timerRef = React.useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const imageSrc = webcamRef.current.getScreenshot({ width: 1, height: 1 });
      upload(imageSrc, (resp) => {
        timerRef.current.reset();
        console.log(timerRef.current);
        setContent(resp);
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  let videoUrl = `https://vk.com/video_ext.php?oid=${content.owner_id}&id=${content.content_id}&hd=2&autoplay=1`;
  return (
    <div>
      <div className="App-wrapper">
        <div>
          <Webcam
            className="App-webcam"
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: "user" }}
          />
          <div className="App-webcam-overlay"></div>
        </div>

        <div className="App-circle">
          {content.type === "wall" && (
            <VKPost
              owner={content.owner_id}
              content={content.content_id}
              sign={content.sign}
            />
          )}

          {content.type === "clip" && (
            <Iframe
              src={videoUrl}
              width="550"
              height="977"
              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
              frameBorder={0}
              allowFullScreen
            ></Iframe>
          )}
        </div>

        {content.type !== null && (
          <div className="App-info">
            <div className="App-info-wrapper">
              <QRCode
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                size={256}
                value={content.url}
                viewBox={"0 0 256 256"}
              />
              <span className="App-info-text">{content.desc}</span>
            </div>
          </div>
        )}
      </div>
      <Timer ref={timerRef}></Timer>
      <img className="App-logo" src={logo} />
      <div className="App-webcam-locker"></div>
    </div>
  );
}

function resizeImageWithRatio(imageWidth, imageHeight, maxWidth, maxHeight) {
  let newWidth, newHeight;

  // Calculate the aspect ratio of the image
  const aspectRatio = imageWidth / imageHeight;

  // Check if the image needs to be resized
  if (imageWidth > maxWidth || imageHeight > maxHeight) {
    // Check if the width is the limiting factor
    if (imageWidth / maxWidth > imageHeight / maxHeight) {
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    } else {
      newHeight = maxHeight;
      newWidth = maxHeight * aspectRatio;
    }
  } else {
    // Image doesn't need to be resized
    newWidth = imageWidth;
    newHeight = imageHeight;
  }

  return { width: newWidth, height: newHeight };
}

export default App;
